import { computed, inject, Ref, provide, ref, ComputedRef } from 'vue'
import useStep from '../useStep'
import { FormAction } from '../useFormAction'
import isNil from 'lodash/isNil'
import isStepSkipped from '../../helpers/isStepSkipped'
import get from 'lodash/get'

export enum FormStepType {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
  PROM = 'PROM',
  PREM = 'PREM',
}

export enum FormStepLayout {
  CONTENT = 'CONTENT',
  MULTI_VALUES = 'MULTI_VALUES',
  QUESTION = 'QUESTION',
  QUESTION_RADIO_GRID = 'QUESTION_RADIO_GRID',
  SLIDER = 'SLIDER',
}

export enum FormPropertyType {
  BAR_GAUGE = 'BAR_GAUGE',
  BUTTON = 'BUTTON',
  BUTTON_SECONDARY = 'BUTTON_SECONDARY',
  CONFIRM = 'CONFIRM',
  EMAIL = 'EMAIL',
  MARKDOWN = 'MARKDOWN',
  NUMBER = 'NUMBER',
  RADIO = 'RADIO',
  ROW_PROPERTIES = 'ROW_PROPERTIES',
  TEL = 'TEL',
  TEXT = 'TEXT',
}

export interface FormSkipIf {
  value?: string
  stepId: string
  nextStepId: string | null
  previousStepId: string | null
  rule?: {
    args?: {
      [key: string]: string | number
    }
    name: string
  }
}

export interface FormProperty {
  action: FormAction | null
  actions: {
    [key: string]: FormAction
  } | null
  label: string | null
  labels: {
    [key: string]: string
  } | null
  name: string | null
  order: number
  pattern: string | null
  patternInstruction: string | null
  properties: FormProperty[] | null
  placeholder: string | null
  score: number | null
  type: FormPropertyType
  value: string | null
}

export interface FormLocale {
  [key: string]: string | { [key: string]: string }
}

export interface FormStep {
  id: string
  answer?: string
  layout: FormStepLayout
  order: number
  type: FormStepType
  showStepper: boolean
  inStepper: boolean
  considerFormAsCompleted: boolean
  optional: boolean
  locales: {
    fr: FormLocale
    en: FormLocale
  }
  properties: FormProperty[]
  skipIf: FormSkipIf[]
  init: FormAction[]
}

const myFormStepper = Symbol()

export default function useFormStep(
  initialFormSteps: ComputedRef<FormStep[]> | Ref<FormStep[]> = ref([]),
) {
  let formSteps = initialFormSteps

  const sortedFormSteps = computed(() =>
    [...formSteps.value].sort((a, b) => a.order - b.order),
  )
  const formStepsCount = computed(() => formSteps.value.length)

  const {
    step: stepIndex,
    goTo,
    nextStep,
    previousStep,
    provideGlobalStepData,
    resetStep,
    useGlobalStepData,
  } = useStep(formStepsCount)

  const formStep = computed(
    () => sortedFormSteps.value?.[stepIndex?.value] ?? null,
  )

  const getSkippedStepNavigation = (
    nextStep: FormStep,
  ): FormSkipIf | undefined => {
    return nextStep.skipIf?.find((condition) => {
      const answer = sortedFormSteps.value.find(
        (step) => step.id === condition.stepId,
      )?.answer

      return isStepSkipped(condition, answer)
    })
  }

  const goToFormStepId = (stepId: string | null): boolean => {
    if (!isNil(stepId) && stepId !== formStep.value?.id) {
      const nextStepIndex = sortedFormSteps.value.findIndex(
        ({ id }) => id === stepId,
      )

      if (nextStepIndex !== -1) {
        if (goTo(nextStepIndex)) {
          const skippedStepNavigation = getSkippedStepNavigation(formStep.value)

          if (skippedStepNavigation) {
            return skippedStepNavigation.nextStepId
              ? goToFormStepId(skippedStepNavigation.nextStepId)
              : nextFormStep()
          }

          return true
        }
      }
    }

    return false
  }

  function getNextFormStepId(index = stepIndex.value): string {
    let nextStep = get(sortedFormSteps.value, index + 1)

    while (nextStep) {
      if (nextStep.skipIf?.length) {
        const skippedStepNavigation = getSkippedStepNavigation(nextStep)

        if (skippedStepNavigation) {
          const targetStepIndex = sortedFormSteps.value.findIndex(
            (step) => step.id === skippedStepNavigation.nextStepId,
          )

          if (targetStepIndex !== -1) {
            nextStep = get(sortedFormSteps.value, targetStepIndex)
            continue
          }

          const nextStepIndex = sortedFormSteps.value.findIndex(
            (step) => step.id === nextStep.id,
          )

          nextStep = sortedFormSteps.value[nextStepIndex + 1]
          continue
        }
      }

      break
    }

    return nextStep?.id || formStep.value.id
  }

  const nextFormStep = (): boolean => {
    if (nextStep()) {
      const skippedStepNavigation = getSkippedStepNavigation(formStep.value)

      if (skippedStepNavigation) {
        return skippedStepNavigation.nextStepId
          ? goToFormStepId(skippedStepNavigation.nextStepId)
          : nextFormStep()
      }

      return true
    }

    return false
  }

  const previousFormStep = (): boolean => {
    if (previousStep()) {
      const skippedStepNavigation = getSkippedStepNavigation(formStep.value)

      if (skippedStepNavigation) {
        return skippedStepNavigation.previousStepId
          ? goToFormStepId(skippedStepNavigation.previousStepId)
          : previousFormStep()
      }

      return true
    }

    return false
  }

  const provideGlobalFormStepData = () => {
    provideGlobalStepData()
    provide(myFormStepper, formSteps)
  }

  const resetFormStep = () => {
    resetStep()
  }

  const useGlobalFormStepData = () => {
    useGlobalStepData()

    const globalData = inject<Ref<FormStep[]>>(myFormStepper)

    if (globalData) {
      formSteps = globalData
    }
  }

  return {
    formStep,
    formStepIndex: stepIndex,
    formSteps: sortedFormSteps,
    formStepsCount,
    getNextFormStepId,
    goToFormStepId,
    nextFormStep,
    previousFormStep,
    provideGlobalFormStepData,
    resetFormStep,
    useGlobalFormStepData,
  }
}
