import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { FormStep, FormStepLayout } from '../../composables/useFormStep'
import getPropertyCount from '../getPropertyCount'

export default function getFormattedLayout(formStep: FormStep): string {
  const { layout, properties } = formStep

  if (!layout) {
    throw new Error(`No layout defined`)
  }

  const {
    barGaugeCount,
    inputCount,
    invalidCount,
    markdownCount,
    radioCount,
    rowCount,
    totalCount,
  } = getPropertyCount(properties || [])

  if (invalidCount) {
    throw new Error(
      `${invalidCount} invalid property type(s) found on the ${layout} layout`,
    )
  }

  switch (layout) {
    case FormStepLayout.CONTENT: {
      if (!!inputCount && !!radioCount) {
        throw new Error(
          `Radio and input properties found on the ${layout} layout`,
        )
      }

      if (inputCount > 1) {
        throw new Error(
          `Too many input properties found on the ${layout} layout`,
        )
      }

      if (radioCount === 1) {
        throw new Error(
          `At least two radio properties are required on the ${layout} layout`,
        )
      }

      break
    }

    case FormStepLayout.MULTI_VALUES: {
      if (radioCount > 0) {
        throw new Error(
          `Radio properties are forbidden on the ${layout} layout`,
        )
      }

      break
    }

    case FormStepLayout.QUESTION: {
      if (!!inputCount && !!radioCount) {
        throw new Error(
          `Radio and input properties found on the ${layout} layout`,
        )
      }

      if (!inputCount && !radioCount) {
        throw new Error(
          `No radio or input property found on the ${layout} layout`,
        )
      }

      if (inputCount > 1) {
        throw new Error(
          `Too many input properties found on the ${layout} layout`,
        )
      }

      if (radioCount === 1) {
        throw new Error(
          `At least two radio properties are required on the ${layout} layout`,
        )
      }

      if (!!barGaugeCount || !!rowCount) {
        throw new Error(
          `BarGauge or Row properties found on the ${layout} layout`,
        )
      }

      break
    }

    case FormStepLayout.QUESTION_RADIO_GRID: {
      if (radioCount !== totalCount) {
        throw new Error(
          `Not all properties found on the ${layout} layout are radio type`,
        )
      }

      break
    }

    case FormStepLayout.SLIDER: {
      if (markdownCount !== totalCount) {
        throw new Error(
          `Not all properties found on the ${layout} layout are markdown type`,
        )
      }

      break
    }

    default: {
      throw new Error(`Layout ${layout} doesn't exist`)
    }
  }

  return `Layout${upperFirst(camelCase(layout))}`
}
