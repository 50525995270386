<script setup lang="ts">
import { computed, watchEffect } from 'vue'
import BaseStepper from '../../components/BaseStepper/index.vue'
import useFormStep from '../../composables/useFormStep'

defineOptions({
  name: 'TheStepper',
})

const { formStep, formSteps, useGlobalFormStepData } = useFormStep()

useGlobalFormStepData()

const showStepper = computed(() => !!formStep.value?.showStepper)
const formStepsInStepper = computed(
  () => formSteps.value.filter(({ inStepper }) => inStepper) || [],
)

const { formStepIndex, formStepsCount, goToFormStepId } =
  useFormStep(formStepsInStepper)

watchEffect(() => {
  goToFormStepId(formStep.value?.id)
})
</script>

<template>
  <teleport to="#header">
    <BaseStepper
      v-show="showStepper"
      :current-step="formStepIndex"
      :steps-count="formStepsCount"
    />
  </teleport>
</template>
